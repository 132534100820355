import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./components/App";
import { connect } from "./ws";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";
import { GlobalError } from "./pages/GlobalError";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  release: process.env.REACT_APP_VERSION,
  dsn:
    process.env.NODE_ENV === "production"
      ? "https://d5fc6923c6b84efda702a8bb433659e9@o354606.ingest.sentry.io/6228391"
      : "",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});

Sentry.setContext("locker", {
  lockerId: localStorage.getItem("lockerId"),
});

Sentry.setTag("locker", localStorage.getItem("lockerId"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<GlobalError />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waiting = registration.waiting;

    if (waiting) {
      waiting.addEventListener("statechange", (event) => {
        const target = event.target as ServiceWorker;
        if (target.state === "activated") {
          window.location.reload();
        }
      });
      waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

connect();
